import update from "immutability-helper";
import { SourcesAction, SourcesActionTypes } from "./Actions";
import { ISourcesState } from "./Models";
import i18n from "../../../ts/i18n";

const initialState: ISourcesState = {
  failed: false,
  fetched: false,
  fetching: false,
  error: '',
  data: [],
  metadata: null,
  failedLinkedResponse: false,
  fetchedLinkedResponse: false,
  fetchingLinkedResponse: false,
  errorLinkedResponse: '',
  linkedResponses: [],
  metadataLinkedResponse: null,
  failedQnAResponse: false,
  fetchedQnAResponse: false,
  fetchingQnAResponse: false,
  errorQnAResponse: '',
  QnAResponses: [],
  metadataQnAResponse: null
};

export const SourcesReducer = (
  state = initialState,
  action: SourcesAction
): ISourcesState => {
  switch (action.type) {
    case SourcesActionTypes.FetchSourcesPending:
      return update(state, {
        fetched: { $set: false },
        fetching: { $set: true },
        failed: { $set: false },
        error: { $set: '' }
      });
    case SourcesActionTypes.FetchSourcesRejected:
      return update(state, {
        data: { $set: [] },
        metadata: { $set: null },
        error: { $set: i18n.t("sources.serverError") },
        fetched: { $set: false },
        fetching: { $set: false },
        failed: { $set: true }
      });
    case SourcesActionTypes.FetchSourcesSuccess:
      let errorMessage = "";
      if(action.payload.sources.data?.length <= 0){
        errorMessage = action.payload.operationType === "list" ? i18n.t("sources.listEmpty") : i18n.t("sources.searchEmpty");
      }
      return update(state, {
        fetched: { $set: true },
        fetching: { $set: false },
        failed: { $set: false },
        error: { $set: errorMessage },
        data: { $set: action.payload.sources.data },
        metadata: { $set: action.payload.sources.metadata },
      });
    case SourcesActionTypes.FetchLinkedResponsesPending:
      return update(state, {
        fetchedLinkedResponse: { $set: false },
        fetchingLinkedResponse: { $set: true },
        failedLinkedResponse: { $set: false },
        errorLinkedResponse: { $set: '' }
      });
    case SourcesActionTypes.FetchLinkedResponsesRejected:
      return update(state, {
        linkedResponses: { $set: [] },
        metadataLinkedResponse: { $set: null },
        errorLinkedResponse: { $set: i18n.t("sources.serverError") },
        fetchedLinkedResponse: { $set: false },
        fetchingLinkedResponse: { $set: false },
        failedLinkedResponse: { $set: true }
      });
    case SourcesActionTypes.FetchLinkedResponsesSuccess:
      let error = "";
      if (action.payload.linkedResponses.data?.length <= 0) {
        error = i18n.t("sources.linkedResponseError");
      }

      let sortedResponses = action.payload.linkedResponses.data.sort((a, b) => a.title.localeCompare(b.title));

      return update(state, {
        fetchedLinkedResponse: { $set: true },
        fetchingLinkedResponse: { $set: false },
        failedLinkedResponse: { $set: false },
        errorLinkedResponse: { $set: error },
        linkedResponses: { $set: sortedResponses },
        metadataLinkedResponse: { $set: action.payload.linkedResponses.metadata }
      });
    case SourcesActionTypes.FetchQnAPending:
      return update(state, {
        fetchedQnAResponse: { $set: false },
        fetchingQnAResponse: { $set: true },
        failedQnAResponse: { $set: false },
        errorQnAResponse: { $set: '' }
      });
    case SourcesActionTypes.FetchQnARejected:
      return update(state, {
        QnAResponses: { $set: [] },
        metadataQnAResponse: { $set: null },
        errorQnAResponse: { $set: i18n.t("sources.serverError") },
        fetchedQnAResponse: { $set: false },
        fetchingQnAResponse: { $set: false },
        failedQnAResponse: { $set: true }
      });
    case SourcesActionTypes.FetchQnASuccess:
      let qnaError = "";
      if (action.payload.qnaResponses.data?.length <= 0) {
        qnaError = i18n.t("sources.linkedResponseError");
      }

      return update(state, {
        fetchedQnAResponse: { $set: true },
        fetchingQnAResponse: { $set: false },
        failedQnAResponse: { $set: false },
        errorQnAResponse: { $set: qnaError },
        QnAResponses: { $set: action.payload.qnaResponses.data },
        metadataQnAResponse: { $set: action.payload.qnaResponses.metadata }
      });
    default:
      return state;
  }
};
