import * as React from "react";
import { Redirect, Route, Router, Switch } from "react-router-dom";
import appHistory from "./AppHistory";

// IMPORT PROJECT REFERENCES

import { LoginCallback, SecureRoute, Security } from "@okta/okta-react";
import {OktaAuth, toRelativeUrl} from "@okta/okta-auth-js";
import { AboutPage } from "./About/Index";
import { Routes } from "./AppConstants";
import AuthPending from "./Auth/AuthPending";
import  Logout  from "./Auth/components/LogoutPage";
import Login from "./Auth/Login";
import PageLayout from "./Base/container/PageLayout";
import { NotFoundPage } from "./Error/NotFoundPage";
import { ErrorBoundary } from "./ErrorBoundary";
import { Home } from "./Home/Index";
import { ManageResponse } from "./ManageResponse/Index";
import { SearchResponses } from "./SearchResponses/Index";
import Sources from "./Sources/Index";
import Analytics from "./Analytics/Index";
import SourcesDocument from "./Sources/container/DocumentResponse/Index";



interface IRouterProps {
  oktaBaseUrl: string;
  oktaClientId: string;
}

const oktaScopes = ["dorc.read", "openid", "profile"];

export class AppRouter extends React.Component<IRouterProps> {

  oktaAuth: OktaAuth;

  constructor(props: any) {
    super(props);
    this.onAuthRequired = this.onAuthRequired.bind(this);
    this.restoreOriginalUri = this.restoreOriginalUri.bind(this);
    this.oktaAuth = new OktaAuth({
      issuer : `${this.props.oktaBaseUrl}/oauth2/default`,
      clientId: this.props.oktaClientId,
      redirectUri:`${window.location.origin}/implicit/callback`,
      scopes: oktaScopes
    });
  }

  public onAuthRequired() {
    appHistory.push(Routes.login);
  }

  public restoreOriginalUri = async (_oktaAuth: OktaAuth, originalUri: string) => {
    appHistory.replace(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  public render() {
    return (
      <Router history={appHistory}>
        <Security
          oktaAuth={this.oktaAuth}
          onAuthRequired={this.onAuthRequired}
          restoreOriginalUri={this.restoreOriginalUri}
        >
          <Switch>
            <Route path={Routes.login} exact={true} component={Login} />
            <Route path="/implicit/callback" exact={true} component={LoginCallback} />
            <PageLayout>
              <ErrorBoundary>
                <Switch>
                  <Route
                    path={Routes.notFound}
                    component={NotFoundPage}
                    exact={true}
                  />

                  <SecureRoute
                    path={`${Routes.home}/(updates|responses|draftresponses)/:pageNumber`}
                    component={Home}
                  />
                  <SecureRoute path="/about" component={AboutPage} />
                  <SecureRoute
                    path={`${Routes.manage}/search/:pageNumber?`}
                    component={SearchResponses}
                  />
                  <SecureRoute
                    path={`${Routes.manage}/:responseId`}
                    component={ManageResponse}
                    exact={true}
                  />
                  <SecureRoute
                    path={`${Routes.manage}/external/:responseId`}
                    component={ManageResponse}
                    exact={true}
                  />
                  <SecureRoute
                    path={`${Routes.sources}`}
                    component={Sources}
                    exact={true}
                  />
                  <SecureRoute
                    path={`${Routes.analytics}`}
                    component={Analytics}
                    exact={true}
                  />
                  <SecureRoute
                    path={`${Routes.sources}/:documentId`}
                    component={SourcesDocument}
                    exact={true}
                  />
                  <Route path={Routes.authenticating} component={AuthPending} />
                  <Route path={Routes.logout} component={Logout} />
                  <Redirect from="/" to={Routes.home} exact={true} />
                  <Redirect from="/implicit/callback" to={Routes.home} />
                  <Redirect
                    from={Routes.home}
                    to={Routes.updateList}
                    exact={true}
                  />
                  <Redirect
                    from={`${Routes.manage}`}
                    to="/manage/search"
                    exact={true}
                  />
                  <Redirect
                    from={Routes.updateList}
                    to={`${Routes.updateList}/1`}
                    exact={true}
                  />
                  <Redirect
                    from={Routes.responseList}
                    to={`${Routes.responseList}/1?searchKeyword=`}
                    exact={true}
                  />
                  <Redirect
                    from={Routes.draftResponseList}
                    to={`${Routes.draftResponseList}/1`}
                    exact={true}
                  />
                  <Redirect to={Routes.authenticating} />
                </Switch>
              </ErrorBoundary>
            </PageLayout>
          </Switch>
        </Security>
      </Router>
    );
  }
}
