
import { AuthState } from '@okta/okta-auth-js';
import { Server } from '../../Shared/Util/Server';
import { IQnAResponseRequestParams } from '../container/DocumentResponse/QnATable/QnATable';
import { ISourceRequestParams } from '../container/SourcesPage';

export function fetchSources(auth: AuthState, requestParams: ISourceRequestParams): Promise<Api.ISourcesResponse|null> {
  return Server.get<Api.ISourcesResponse>(`/documents?pageSize=${requestParams.pageSize}&pageNumber=${requestParams.pageNumber}`, auth);
}

export function fetchSearchSources(auth: AuthState, searchText:string, requestParams: ISourceRequestParams): Promise<Api.ISourcesResponse|null> {
  return Server.get<Api.ISourcesResponse>(`/documents/search?query=${searchText}&pageSize=${requestParams.pageSize}&pageNumber=${requestParams.pageNumber}`, auth);
}

export function fetchLinkedResponses(auth: AuthState, requestParams: ISourceRequestParams, documentId: string, tenantName: string): Promise<Api.ILinkedResponses | null> {
  return Server.get<Api.ILinkedResponses>(`/Response/document/${documentId}?resultsPerPage=${requestParams.pageSize}&pageNumber=${requestParams.pageNumber}`, auth);
}

export function fetchQnAResponses(auth: AuthState, requestParams: IQnAResponseRequestParams, tenantName: string): Promise<Api.IQnAResponses | null> {
  return Server.get<Api.IQnAResponses>(`/qnaresponses?pageSize=${requestParams.pageSize}&pageNumber=${requestParams.pageNumber}`, auth);
}
