import { FunctionComponent, useEffect, useState } from "react";
import i18n from "../../../i18n";
import { WithOktaAuthProps } from "src/ts/Auth/duck/Models";
import '../SourcesPage.scss';
import { Tooltip } from "react-tooltip";
import { useLocation } from "react-router-dom";
import './SourcesDocumentPage.scss';
import { HeaderField, Table, TableBody, TableField, TableHeader, TableRow } from "@panviva/panviva-react-ui";
import { ISourcesState } from "../../duck/Models";
import { IStoreState } from "../../../Model";
import { useDispatch, useSelector } from "react-redux";
import * as moment from "moment";
import { LoadingIndicator } from "../../../Shared/LoadingIndicator";
import { getLinkedResponsesThunk } from "../../duck/Actions";
import appHistory from "../../../AppHistory";
import { QnATable } from "./QnATable/QnATable";

const redirectToDocument = (link: string) => {
  window.open(link);
}

export interface ILinkedResponseRequestParams {
  pageNumber: number;
  pageSize: number;
}

const GridTitles = ['Response Title', 'Status', 'Category', 'Last Updated'];

export const SourcesDocumentPage: FunctionComponent<WithOktaAuthProps> = (props: WithOktaAuthProps) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const data: any = location.state;
  const linkedResponsesDetails = useSelector((state: IStoreState) => state.sources);
  const [linkedResponsesData, setlinkedResponsesData] = useState<ISourcesState>();
  const [query, setQuery] = useState<string>();
  const [searchDisabled, setSearchDisabled] = useState<boolean>();
  const [requestParams, setRequestParams] = useState<ILinkedResponseRequestParams>({ pageNumber: 1, pageSize: 10 });
  const enableExternalDataSources = useSelector((state: IStoreState) => state.tenants.featureSettings.enableExternalDataSources);
  let hideQnASection = true; // Hiding Q&A section for now, will continue afterwards

  useEffect(() => {
    setlinkedResponsesData(linkedResponsesDetails);
  }, [linkedResponsesDetails])

  useEffect(() => {
    handlePagination(requestParams);
  }, [requestParams]);

  const getLinkedResponsesData = (requestParams: ILinkedResponseRequestParams, documentId: string) => {
    dispatch(getLinkedResponsesThunk(props.authState, requestParams, documentId))
  }

  const handlePagination = (requestParams: ILinkedResponseRequestParams) => {
    getLinkedResponsesData(requestParams, data.documentId);
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    event.preventDefault();
    setRequestParams({
      ...requestParams,
      pageSize: parseInt(event.target.value, 10),
      pageNumber: 1
    });
  };

  const handleFirstPageChange = () => {
    setRequestParams({ ...requestParams, pageNumber: 1 })
  };

  const handlePrevPageChange = () => {
    setRequestParams({ ...requestParams, pageNumber: requestParams.pageNumber - 1 })
  };

  const handleNextPageChange = () => {
    setRequestParams({ ...requestParams, pageNumber: requestParams.pageNumber + 1 })
  };

  const handleLastPageChange = () => {
    setRequestParams({
      ...requestParams,
      pageNumber: (linkedResponsesData?.metadataLinkedResponse?.totalCount && linkedResponsesData?.metadataLinkedResponse?.totalCount > 0)
        ? Math.ceil(linkedResponsesData?.metadataLinkedResponse?.totalCount / requestParams.pageSize)
        : 1
    })
  };

  const hanldeCurateButtonClick = (documentId: number) => {
    let enableCurateDocument = Boolean(enableExternalDataSources);
    appHistory.push(`/manage/0?documentId=${documentId}&primaryQuery=${query}&isExternal=${enableCurateDocument}`);
  }

  const handleInputChange = (e: React.FormEvent<HTMLInputElement>) => {
    if (!e.currentTarget.value) {
      setQuery("");
      setSearchDisabled(true);
    } else {

      setQuery(e.currentTarget.value);
      setSearchDisabled(false);
    }
  }
  const redirectToManageResponsePage = (id: string, documentId: string) => {
    let enableCurateDocument = Boolean(enableExternalDataSources);
    appHistory.push(`/manage/external/${id}?isExternal=${enableCurateDocument}`);
  }

  return (
    <div className="sources-container">
      <h3 className="sources-title">{i18n.t("sources.heading")}</h3>
      <span className="document-title">{data.documentName}
        <img className="document-icon" src="/images/path.svg" data-tooltip-id="document-name" data-tooltip-content={data.link} onClick={() => redirectToDocument(data.link)} />
        <Tooltip
          id="document-name"
          place={"right"}
          variant={"info"}
          className={"cc-tooltip linked-responses-tooltip"} />
      </span>

      <div className="flex-row">
        <div className="linked-responses-page width-62">
          <img className="document-icon" src="/images/1.svg" />
          <span className="textTitle">Linked Responses</span>
          <span className="textDescription ">Existing responses linked to this document, ready for multi-channel delivery </span>
          <div className="linked-responses-table">
            <Table
              className="linked-responses-container"
              totalCount={linkedResponsesData?.metadataLinkedResponse?.totalCount}
              totalPages={linkedResponsesData?.metadataLinkedResponse?.totalPages}
              currentPage={linkedResponsesData?.metadataLinkedResponse?.currentPage}
              rowsPerPage={linkedResponsesData?.metadataLinkedResponse?.pageSize}
              setRowsPerPage={(e: React.ChangeEvent<HTMLSelectElement>) =>
                handleChangeRowsPerPage(e)
              }
              handleFirstPage={handleFirstPageChange}
              handlePrevPage={handlePrevPageChange}
              handleNextPage={handleNextPageChange}
              handleLastPage={handleLastPageChange}
            >

              <TableHeader className="linked-responses-table-header">
                <TableRow type="header">
                  {GridTitles.map((title: string) => {
                    return <HeaderField key={title} className="linked-responses-heading">
                      {title}
                    </HeaderField>
                  })}
                </TableRow>
              </TableHeader>

              <LoadingIndicator busy={linkedResponsesData?.fetchingLinkedResponse || false} />

              {linkedResponsesData?.errorLinkedResponse ?
                <div className=" linked-responses-table-error">
                  <p>{linkedResponsesData?.errorLinkedResponse}</p>
                </div>
                :
                <TableBody>
                  {linkedResponsesData?.linkedResponses?.map((cell: Api.ILinkedResponseData, index: number) => {
                    return <TableRow key={`${cell.title}_${index}`} onClick={() => redirectToManageResponsePage(cell.id, "1234")}
                    >
                      <TableField className="linked-responses-data">
                        <a
                          href=""
                          data-tooltip-id={`${cell.title}-default-tooltip`}
                          data-tooltip-content={cell.title}
                          className="linked-responses-cell-link"
                        >
                          {cell.title}
                        </a>
                        <Tooltip
                          id={`${cell.title}-default-tooltip`}
                          place={"right"}
                          variant={"info"}
                          className={"cc-tooltip linked-responses-tooltip"} />
                      </TableField>
                      <TableField className="linked-responses-data">
                        <div className="linked-responses-table-tags-data">
                          <span
                            key={cell.status}
                            className="linked-responses-cell-tags">
                            {cell.status}
                          </span>
                        </div>
                      </TableField>
                      <TableField className="linked-responses-data">
                        <div
                          className="linked-responses-cell-name">
                          {cell.category}
                        </div>
                      </TableField>
                      <TableField className="linked-responses-data">
                        {moment(cell.dateModified).format("MM/DD/YY HH:ss")}
                      </TableField>
                    </TableRow>
                  })}
                </TableBody>
              }
            </Table>
          </div>

        </div>
        {
          hideQnASection ? null : <QnATable {...props} />
        }
      </div>

      <div className="linked-responses-curation">
        <img className="document-icon" src="/images/3.svg" />
        <span className="textTitle">Curation</span>
        <span className="curationTextDescription">Create new responses by entering a primary query</span>
        <form noValidate={true} className="form-inline" onSubmit={() => hanldeCurateButtonClick(1234)}>
          <div className="input-group input-group-lg search-group">

            <input type="text" aria-label="search-input" autoFocus={true} className="form-control" //className={classnames('form-control', { 'is-invalid': this.state.externalSourcesError })}
              value={query}
              onChange={(e) => handleInputChange(e)}
              placeholder={i18n.t("manage.curationPlaceholderText")} />
            <button type="submit" className="btn btn-primary" disabled={searchDisabled}>
              {i18n.t("manage.search")}
            </button>
          </div>
        </form>
      </div>

    </div>);
}
