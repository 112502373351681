import { AuthState } from "@okta/okta-auth-js";
import * as React from "react";
import { compositeResponseListDefaultSort, Routes } from "../../../AppConstants";
import appHistory from "../../../AppHistory";
import i18n from "../../../i18n";
import Pagination from "../../../Shared/Pagination/Index";
import './CompositeResponseList.scss';
import { CompositeResponseListRow } from "./CompositeResponseListRow";

export interface IResponseListProps {
  responses: Api.IPagedResult<Api.ICompositeResponseViewModel>;
  docId: number;
  handlePageSizeChange: (pageNumber: number, itemPerPage: number, sortOrder: string, auth: AuthState | null) => any;
  externalSourcesResponses: Api.IExternalSourcesSearchReducerResponse;
  isPanvivaCoreEnabled: boolean;
  handleExternalSourcesPageSizeChange: (pageNumber: number, itemPerPage: number, auth: AuthState | null) => any;
  authState: AuthState | null;
}

class CompositeResponseList extends React.Component<IResponseListProps> {
  constructor(props: IResponseListProps) {
    super(props);

    this.handlePageSizeChange = this.handlePageSizeChange.bind(this);
    this.sortOrderChanged = this.sortOrderChanged.bind(this);
    this.handleExternalSourcesPageSizeChange = this.handleExternalSourcesPageSizeChange.bind(this);
  }

  

  public render() {
    // ResponseListPage handles this logic in componentDidMount or Update
    const sortOrder = this.props.responses.sortOrder || `-${compositeResponseListDefaultSort}`
    const clsName =
      sortOrder.indexOf("-") > -1
        ? "fa fa-sort-amount-down"
        : "fa fa-sort-amount-up";

    var defaultStateForResponses = {
      referenceId: 1,
      category: 'Test',
      dateModified: new Date(),
      id: 1,
      title: 'Title',
      primaryQuery: '',
      status: 'Draft'
    }
    var defaultStateForExternalSources = {
      referenceId: 1,
      category: 'Test',
      dateModified: new Date(),
      id: "1",
      title: 'Title',
      primaryQuery: '',
      status: 'Draft',
      documentIds: []
    }

    return (
      <>
        {
          (this.props.responses.results && this.props.responses.results.length && this.props.isPanvivaCoreEnabled) || (this.props.externalSourcesResponses.data && this.props.externalSourcesResponses.data.length && !this.props.isPanvivaCoreEnabled) ?
            < div className="composite-response-list" >
              <table className="table table-hover table-responsive">
                <thead>
                  <tr>
                    <th className="title">{i18n.t("responseList.titleColHeading")}</th>
                    <th className="status">{i18n.t("responseList.dateColStatus")}</th>
                    <th className="category">{i18n.t("responseList.categoryHeading")}</th>
                    <th className="date">
                      <span className="date-label">{i18n.t("responseList.dateColHeading")}</span>
                      {this.props.isPanvivaCoreEnabled ?
                        <i className={clsName} onClick={this.sortOrderChanged} /> : null
                      }
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {
                    this.props.isPanvivaCoreEnabled ? <>{this.props.responses.results && this.props.responses.results.map((response, i) => <CompositeResponseListRow response={response} key={i} externalSourcesResponse={defaultStateForExternalSources} isPanvivaCoreEnabled={this.props.isPanvivaCoreEnabled} />)}</> :
                      <>{this.props.externalSourcesResponses.data && this.props.externalSourcesResponses.data.map((response, i) => <CompositeResponseListRow externalSourcesResponse={response} key={i} response={defaultStateForResponses} isPanvivaCoreEnabled={this.props.isPanvivaCoreEnabled} />)}</>
                  }
                </tbody>
              </table>

              {
                this.props.isPanvivaCoreEnabled ?
                  <Pagination onPageSizeChange={this.handlePageSizeChange} pageSize={this.props.responses.pageSize} currentPage={this.props.responses.currentPage} linkUrlLeftPart="/manage/search" totalRecordsNumber={this.props.responses.rowCount} pageCount={this.props.responses.pageCount} authState={this.props.authState} /> :
                  <Pagination onPageSizeChange={this.handleExternalSourcesPageSizeChange} pageSize={this.props.externalSourcesResponses.pageSize} currentPage={this.props.externalSourcesResponses.currentPage} linkUrlLeftPart="/manage/search" totalRecordsNumber={this.props.externalSourcesResponses.totalCount} pageCount={this.props.externalSourcesResponses.totalPages} authState={this.props.authState} />
              }

            </div > : null
        }
      </>
    )
  }

  private sortOrderChanged(): any {
    let url = `${Routes.manageSearch}/1?docId=${this.props.docId}`;
    if (this.props.responses.sortOrder &&
      this.props.responses.sortOrder.indexOf("-") > -1) {
      url = `${url}&sortOrder=${compositeResponseListDefaultSort}`;
    } else {
      url = `${url}&sortOrder=-${compositeResponseListDefaultSort}`;
    }

    appHistory.push(url);
  }

  private handlePageSizeChange(pageNumber: number, itemPerPage: number) {
    this.props.handlePageSizeChange(
      pageNumber,
      itemPerPage,
      this.props.responses.sortOrder,
      this.props.authState
    );
  }

  private handleExternalSourcesPageSizeChange(pageNumber: number, itemPerPage: number) {
    this.props.handleExternalSourcesPageSizeChange(
      pageNumber,
      itemPerPage,
      this.props.authState
    );
  }
}

export { CompositeResponseList };
