import * as React from "react";
import { WrappedFieldProps } from "redux-form";
import { IExternalDocumentChunksData, IExternalDocumentNumberingData, IOrderedSection, ISection } from "../../duck/Models";
import { SectionSelector } from "./SectionSelector";

type ReduxSectionSelectorProps = WrappedFieldProps & {
  sections?: ISection[];
  sectionDeleted: boolean;
  panvivaDocumentDeleted: boolean;
  fetchingPanvivaDocument: boolean;
  disabledContentCuration: boolean;
  enableAIAssistant: boolean;
  fetchContentCuration: Function;
  setPanvivaDocumentSections: Function;
  undoSections: ISection[][],
  undoSectionAction: Function;
  navigatedPageStateForSections: boolean;
  setNavigatedPageStateForSections: Function;
  newExternalDocumentChunks: IExternalDocumentChunksData[];
  currentExternalDocumentChunks: IExternalDocumentChunksData[];
  isExternal: boolean;
  enableAIAssistantForChunks: boolean;
  documentNumbering: IExternalDocumentNumberingData[];
};

export class ReduxSectionSelector extends React.Component<
  ReduxSectionSelectorProps
> {
  constructor(props: ReduxSectionSelectorProps) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  public render() {
    let mappedSections: ISection[] = [];
    if (this.props.sections) {
      const formSections = this.props.input.value ? (this.props.input.value as ISection[]) : [];

      mappedSections = this.props.sections!.reduce((aggregatedSections: ISection[], currentSection: ISection) => {
        const taggedSection = formSections.find(section => currentSection.sectionId === section.sectionId);
        aggregatedSections.push(taggedSection ? taggedSection : currentSection);
        return aggregatedSections;
      }, []);
    }

    return (
      <SectionSelector
        sections={mappedSections}
        sectionDeleted={this.props.sectionDeleted}
        panvivaDocumentDeleted={this.props.panvivaDocumentDeleted}
        fetchingPanvivaDocument={this.props.fetchingPanvivaDocument}
        name={this.props.input.name}
        onSelectSections={this.onChange}
        validationErrorText={this.props.meta.error}
        disabledContentCuration={this.props.disabledContentCuration}
        fetchContentCuration={this.props.fetchContentCuration}
        enableAIAssistant={this.props.enableAIAssistant}
        undoSections={this.props.undoSections}
        undoSectionAction={this.props.undoSectionAction}
        navigatedPageStateForSections={this.props.navigatedPageStateForSections}
        isExternal={this.props.isExternal}
        currentExternalDocumentChunks={this.props.currentExternalDocumentChunks}
        newExternalDocumentChunks={this.props.newExternalDocumentChunks}
        enableAIAssistantForChunks={this.props.enableAIAssistantForChunks}
        documentNumbering={this.props.documentNumbering}
      />
    );
  }

  public onChange(selectedSections: IOrderedSection[]) {
    this.props.setNavigatedPageStateForSections();
    this.props.input.onChange(selectedSections);
    this.props.setPanvivaDocumentSections(selectedSections);
  }
}
