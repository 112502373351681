import { FunctionComponent, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ISourcesState } from "../../duck/Models";
import { IStoreState } from "src/ts/Model";
import { HeaderField, Table, TableBody, TableField, TableHeader, TableRow } from "@panviva/panviva-react-ui";
import './SourceTable.scss';
import * as moment from "moment";
import { Tooltip } from "react-tooltip";
import { LoadingIndicator } from "../../../../ts/Shared/LoadingIndicator";
import { ISourceRequestParams } from "../../container/SourcesPage";
import appHistory from "../../../AppHistory";

interface ISourceTableProps {
    searchTextState: string;
    onPaginationChange: (requestParams:ISourceRequestParams) => void
}

const GridTitles = ['Document Name','Source Path','Link', 'Last Updated','Tags','Source','Version'];

const SourceTable: FunctionComponent<ISourceTableProps> = (props) => {

    const { onPaginationChange } = props;
    const sourceDetails = useSelector((state:IStoreState)=> state.sources);
    const [sourceData ,setSourceData] = useState<ISourcesState>();
    const [requestParams, setRequestParams] = useState<ISourceRequestParams>({pageNumber: 1, pageSize: 10});

    useEffect(()=>{
        setSourceData(sourceDetails);
    },[sourceDetails])

    useEffect(()=>{
        onPaginationChange(requestParams);
    },[requestParams])

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLSelectElement>
    ) => {
        event.preventDefault();
        setRequestParams({
            ...requestParams,
            pageSize: parseInt(event.target.value, 10),
            pageNumber: 1
        });
    };

    const handleFirstPageChange = () => {
        setRequestParams({...requestParams, pageNumber: 1})
    };

    const handlePrevPageChange = () => {
        setRequestParams({...requestParams, pageNumber: requestParams.pageNumber - 1})
    };

    const handleNextPageChange = () => {
        setRequestParams({...requestParams, pageNumber: requestParams.pageNumber + 1})
    };

    const handleLastPageChange = () => {
        setRequestParams({...requestParams,
            pageNumber: (sourceData?.metadata?.totalCount && sourceData?.metadata?.totalCount > 0)
            ? Math.ceil(sourceData?.metadata?.totalCount / requestParams.pageSize)
            : 1
        })
    };

  const redirectToDocument = (documentId: string, name: string, link: string) => {
    appHistory.push(`/sources/${documentId}`, { documentName: name, link: link, documentId: documentId });
  }

    return ( 
        <div className="source-table">
                <Table
                    className="source-table-container"
                    totalCount={sourceData?.metadata?.totalCount}
                    totalPages={sourceData?.metadata?.totalPages}
                    currentPage={sourceData?.metadata?.currentPage}
                    rowsPerPage={sourceData?.metadata?.pageSize}
                    setRowsPerPage={(e: React.ChangeEvent<HTMLSelectElement>) =>
                        handleChangeRowsPerPage(e)
                    }
                    handleFirstPage={handleFirstPageChange}
                    handlePrevPage={handlePrevPageChange}
                    handleNextPage={handleNextPageChange}
                    handleLastPage={handleLastPageChange}
                    >
                    
                    <TableHeader>
                        <TableRow type="header">
                            {GridTitles.map((title:string)=>{
                                return <HeaderField key={title} className="source-table-heading">
                                        {title}
                                        </HeaderField>
                            })}                        
                        </TableRow>
                    </TableHeader>

                    <LoadingIndicator busy={sourceData?.fetching || false} />

                    {sourceData?.error ?
                    <div className="source-table-error">
                        <p>{sourceData?.error}</p>
                    </div>
                    :
                    <TableBody>
                        {sourceData?.data?.map((cell:Api.ISourcesData, index:number)=>{
                            return <TableRow key={`${cell.name}${index}`}>
                                    <TableField className="source-table-data">
                                        <a
                                            href=""
                                            className="source-cell-link"
                                            onClick={()=> redirectToDocument(cell.id, cell.name, cell.url)}
                                            data-tooltip-id={`${cell.name}-default-tooltip`}
                                            data-tooltip-content={cell.name}>
                                            {cell.name}
                                        </a>
                                        <Tooltip
                                                id={`${cell.name}-default-tooltip`}
                                                place={"right"}
                                                variant={"info"}
                                                className={"cc-tooltip source-tooltip"} />
                                    </TableField>
                                    <TableField className="source-table-data">
                                        <div className="source-cell-path">
                                        <span data-tooltip-id={`${cell.path}-default-tooltip`}
                                            data-tooltip-content={cell.path}>
                                                {cell.path}
                                        </span>
                                            <Tooltip
                                                id={`${cell.path}-default-tooltip`}
                                                place={"right"}
                                                variant={"info"}
                                                className={"cc-tooltip source-tooltip"}
                                                />
                                        </div>
                                    </TableField>
                                    <TableField className="source-table-data">
                                        <div>
                                            <a
                                                href={cell.url}
                                                target="_blank"
                                                className="source-path-icon"
                                                data-tooltip-id={`${cell.path}-icon-default-tooltip`}
                                                data-tooltip-content={cell.url}>
                                                <img src="/images/path.svg"/>
                                            </a>
                                            <Tooltip
                                                id={`${cell.path}-icon-default-tooltip`}
                                                place={"right"}
                                                variant={"info"}
                                                className={"cc-tooltip source-tooltip"}/>
                                        </div>
                                    </TableField>
                                    <TableField className="source-table-data">
                                        {moment(cell.updated).format("MM/DD/YY HH:ss")}
                                    </TableField>
                                    <TableField className="source-table-cell">
                                        <div
                                            className="source-table-tags-data"
                                            data-tooltip-id="tags-default-tooltip"
                                            data-tooltip-content={cell.tags.join("\n")}>
                                            {cell.tags.map((tag:string)=>{
                                                return <span
                                                            key={tag}                                                     
                                                            className="source-cell-tags">
                                                            {tag}
                                                        </span>                                                    
                                            })}                                        
                                            <Tooltip
                                                id="tags-default-tooltip"
                                                place={"right"}
                                                variant={"info"}
                                                className={"cc-tooltip source-tooltip"} />
                                        </div>
                                    </TableField>
                                    <TableField className="source-table-data">
                                        <div
                                            className="source-cell-name"
                                            data-tooltip-id={`${cell.source}-default-tooltip`}
                                            data-tooltip-content={cell.source.toString()}>
                                            {cell.source}
                                        </div>
                                        <Tooltip
                                                id={`${cell.source}-default-tooltip`}
                                                place={"right"}
                                                variant={"info"}
                                                className={"cc-tooltip source-tooltip"} />
                                    </TableField>
                                    <TableField className="source-table-data">
                                        {cell.version}
                                    </TableField>
                            </TableRow>
                        })}
                    </TableBody>
                    }
                </Table>
        </div>  );
}
 
export default SourceTable;
