import { AuthState } from '@okta/okta-auth-js';
import appHistory from '../../AppHistory';
import { AppLogger } from './../../AppLogger';

// Server url
const HostUrl = "/api";

const callApi = <T>(apiUrl: string, method: RequestMethod, authState: AuthState, data?: any, acceptType?: string): Promise<T> => {
  const { accessToken } = authState;
  return fetch(HostUrl + apiUrl, {
    body: data ? data : undefined,
    headers: new Headers({
      'Accept': acceptType || 'application/json',
      'Authorization': 'Bearer ' + accessToken?.accessToken,
      'Content-Type': 'application/json',
      'pragma': 'no-cache',
      'cache-control': 'no-cache'
    }),
    method
  })
    .then(parseJSON)
    .then(handleErrors)

};

const callApiAnonymously = <T>(apiUrl: string, method: RequestMethod, data?: any): Promise<T> => {
  return fetch(HostUrl + apiUrl, {
    body: data ? data : undefined,
    headers: new Headers({
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'pragma': 'no-cache',
      'cache-control': 'no-cache'
    }),
    method
  })
    .then(parseJSON)
    .then(handleErrors);
}

export class ApiError extends Error {
  constructor(m: string, public status: number, public apiResponse: any) {
    super(m);

    // Set the prototype explicitly.
    Object.setPrototypeOf(this, ApiError.prototype);
  }
}

const handleErrors = (res: any) => {
  if (!res.ok) {
    if (res.status === 401) {
      // Token has expired, purge auth state so the user is redirected to login
      appHistory.push('/login');
    }
    let message = "";
    try {
      message = JSON.stringify(res.json);
    } catch {
      message = res.json;
    }
    const exception = new ApiError(message, res.status, res.json);

    // Track Exception with Azure App Insights
    AppLogger.logException(exception);

    throw exception;
  }
  return res.json;
}

const parseJSON = (response: Response) => {
  return new Promise((resolve) => {
    response.text()
      .then((content) => {
        let json: any;
        if (response.ok || response.status < 500) {
          if (content) {
            try {
              json = JSON.parse(content);
            } catch {
              json = content;
            }
          } else {
            json = {};
          }
        }
        resolve({
          status: response.status,
          ok: response.ok,
          json,
        });
      })
  });
}

enum RequestMethod {
  Get = "GET",
  Post = "POST",
  Delete = "DELETE",
  Put = "PUT"
}

export class Server {
  public static get = <T>(apiUrl: string, authState: AuthState, acceptType?: string): Promise<T> => {
    return callApi(apiUrl, RequestMethod.Get, authState, null, acceptType);
  }

  public static post = <T>(apiUrl: string, authState: AuthState, data?: any): Promise<T> => {
    return callApi(apiUrl, RequestMethod.Post, authState, JSON.stringify(data));
  }

  public static postAsync = async <T>(apiUrl: string, authState: AuthState, data?: any): Promise<T> => {
    return await callApi(apiUrl, RequestMethod.Post, authState, JSON.stringify(data));
  }

  public static put = <T>(apiUrl: string, authState: AuthState, data?: any): Promise<T> => {
    return callApi(apiUrl, RequestMethod.Put, authState, JSON.stringify(data));
  }

  public static putAsync = async <T>(apiUrl: string, authState: AuthState, data?: any): Promise<T> => {
    return await callApi(apiUrl, RequestMethod.Put, authState, JSON.stringify(data));
  }

  public static delete = <T>(apiUrl: string, authState: AuthState, data?: any): Promise<T> => {
    return callApi(apiUrl, RequestMethod.Delete, authState, JSON.stringify(data));
  }

  public static anonymousGet = <T>(apiUrl: string): Promise<T> => {
    return callApiAnonymously(apiUrl, RequestMethod.Get, false);
  }
}

